import React, { useEffect, useState } from "react";
import EPSALOGO from "../../assets/EpsaLogo.png";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import fanosLogo from "../../assets/fanos-logo.png";
import { Button } from "react-bootstrap";
import { Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { getEnvironemntList, getProgramGroup } from "../../Services/ApiManager";

import { FaHome, FaBars, FaBell, FaExclamationTriangle } from "react-icons/fa";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";

import { useFanosStore } from "./Shared Utils/store";
import { CONSTANTS } from "../../Utils/Constants";
import { DownloadData } from "../Download Data/DownloadData";

export default function NavBar({title}) {
  const notifications = [
    {
      type: "icon",
      label: "Notification",
      submenus: [
        {
          label: "Potential Expiries",
          description: "Review list of products to expire and take action"
        },
        {
          label: "Overage Report",
          description: "Overage Report"
        }
      ]
    }
  ];

  const history = useNavigate();
  const options = useFanosStore((state) => state.options);
  const [menuOptions, setMenuOptions] = useState(JSON.parse(JSON.stringify(options)));
  const setAllPrograms = useFanosStore((state) => state.setAllPrograms);
  const setAllLocations = useFanosStore((state) => state.setAllLocations);
  const showDownloadModal = useFanosStore((state) => state.showDownloadModal);
  const setShowDownloadModal = useFanosStore((state) => state.setShowDownloadModal);

  useEffect(() => {
    function fetchData() {
      const user = localStorage.getItem("userInfo");
      if (!user) history("/login");
      const userId = JSON.parse(user)?.GroupID;
      
      getProgramGroup(userId).then((data) => {
        setAllPrograms(JSON.parse(user)?.UserAccountID === 4443? data.data.Data.filter((d) => (d.ProgramCode === 'FP')): data.data.Data);
      });

      getEnvironemntList().then((data) => {
        setAllLocations(data.data.Data);
        data.data.Data = [
          {
            Environment: "National",
            EnvironmentCode: "National",
            EnvironmentGroup: "PFSA",
          },
          ...data.data.Data,
        ];
      });
    }
    fetchData();
  }, []);

  useEffect(() => {
    setMenuOptions(JSON.parse(JSON.stringify(options)))
  }, [options])

  const downloadManual = () => {
    const filePath = 'resources/Fanos_User_Manual.pdf';

    const link = document.createElement('a');
    link.href = filePath;
    link.download = 'Fanos_User_Manual.pdf';

    document.body.appendChild(link);

    link.click();
  }

  const OptionsWrapper = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    
    return (
      <div>
        <Button
          id="demo-customized-button"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          style={open?
            {backgroundColor: "white", color: 'black', marginRight: "3px", borderRadius: "5px 5px 0 0"}:
            {color: "white", marginRight: "3px", borderRadius: "5px 5px 0 0"}}
          endIcon={<KeyboardArrowDownIcon />}
        >
          <FaBars fontSize={23}/>
        </Button>
        <Menu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
            {JSON.parse(localStorage.getItem("userInfo")).Username === CONSTANTS.adminUser && 
            <MenuItem disableRipple style={{borderBottom: "1px solid #dddddd", padding: '10px 40px 10px 30px', fontSize: '12px', fontWeight: '600'}} 
              onClick={(e)=> {
                handleClose(e)
                history("/admin");
              }}>
              ADMIN
            </MenuItem>}
            {JSON.parse(localStorage.getItem("userInfo")).Username === CONSTANTS.adminUser && 
            <MenuItem disableRipple style={{borderBottom: "1px solid #dddddd", padding: '10px 40px 10px 30px', fontSize: '12px', fontWeight: '600'}} 
              onClick={()=> setShowDownloadModal(true)}>
              Download Data
            </MenuItem>}
            <MenuItem disableRipple style={{borderBottom: "1px solid #dddddd", padding: '10px 40px 10px 30px', fontSize: '12px', fontWeight: '600'}} 
              onClick={()=> downloadManual()}>
              User Manual
            </MenuItem>
            {JSON.parse(localStorage.getItem("userInfo")).Username === CONSTANTS.adminUser && 
            <MenuItem disableRipple style={{borderBottom: "1px solid #dddddd", padding: '10px 40px 10px 30px', fontSize: '12px', fontWeight: '600'}} 
             onClick={(e)=> {
                handleClose(e)
                history("/Formulas");
              }}>
              Formulas
            </MenuItem>}
            <MenuItem disableRipple style={{borderBottom: "1px solid #dddddd", padding: '10px 40px 10px 30px', fontSize: '12px', fontWeight: '600'}} 
              onClick={(e)=> {
                handleClose(e)
                localStorage.removeItem("userInfo");
                history("/login");
              }}>
              LOGOUT
            </MenuItem>
        </Menu>
      </div>
    );
  };

  function slide(direction) {
    var tempMenuList = JSON.parse(JSON.stringify(menuOptions));
    if (direction === "left") {
      tempMenuList.unshift(tempMenuList.pop());
      setMenuOptions(JSON.parse(JSON.stringify(tempMenuList)));
    }
    else if(direction === "right") {    
      tempMenuList.push(tempMenuList.shift());
      setMenuOptions(JSON.parse(JSON.stringify(tempMenuList)));
    }
  }

  return (
    <Wrapper>
      <div>
        <div className="flex-row"
          style={{width: "100%", alignItems: "center"}}>
          <div>
            <img
              src={EPSALOGO}
              alt="loading"
              style={{
                height: "50px",
              }}
              className="pfsa-logo"
            />
          </div>
          <div className="lp_inputGrp_logo">
            <img
              src={fanosLogo}
              alt="loading.."
              className="lp_input_logo_img px-0"
            />
          </div>
          <div className="enlp_inputGrp_txt mt-3" style={{flex: 100}}>
            <h5 className="page-title-default">Fanos Dashboard</h5>
            <h3 className="page-title nlp_input_logo_txt mx-auto">{title}</h3>
          </div>
          <div className="lp_inputGrp_logo"></div>
          <div style={{ fontSize: "20px", margin: "auto", alignSelf: "flex-end", flex: 1 }}>
            <OptionsWrapper />
          </div>
        </div>
      </div>
      <div className="main-menu" style={{display: "flex", paddingLeft: "0.5rem", alignItems: "center"}}>
        <div onClick={() => history('/')} style={{display: "flex", padding: "5px 1rem 2px 0.5rem"}} >
          <FaHome size={25} />
        </div>
        
        <div className="menu-slider">
          <IoIosArrowDropleft size={30} onClick={() => slide('left')}/>
        </div>
          <div className="menu-list">
            <div className="flex-row">
              {menuOptions.map((option) => (
                <MenuWrapper option={option} />
              ))}
            </div>
          </div>
        <div className="menu-slider">
          <IoIosArrowDropright size={30} onClick={() => slide('right')}/>
        </div>
      
        <div style={{ 
          position: "absolute", left: "95.5%" }}>
          {notifications.map((option) => (
            <MenuWrapper option={option} />
          ))}
        </div>
      </div>
      {showDownloadModal && <DownloadData dialogOpen={showDownloadModal} setDialogOpen={setShowDownloadModal}/>}
    </Wrapper>
  );
}

const MenuWrapper = ({ option }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onMenuClick = (menu, type, linkAddress) => {
    let isRoute;
    const menuName = menu.label.replace(/\s+/g, "");
    if(linkAddress)
      window.open(linkAddress, '_blank');
    type !== "Program" && type !== "Location" && !linkAddress ? (isRoute = true) : (isRoute = false);
    isRoute && history(`/${menuName}`);
    if(type === "Program" && menu.label.includes('/')){
      history(`/Programs/Viral-Viral`, {state: menu,});
    } else if(type === "Program") {
      history(`/Programs/${menu.label.replace(/\s+/g, "").replace("/", "")}-${menu.label}`, {state: menu,});
      window.location.reload();
    }
    type === "Location" && history(`/Locations/${menu.label.replace(/\s+/g,"")}`, {state: menu,});
  }

  return (
    <div>
      { option.type !== 'icon' &&
          <Button
            id="demo-customized-button"
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            style={open?
              {backgroundColor: "white", minWidth: "5rem", color: 'black', marginRight: "3px", borderRadius: "5px 5px 0 0", whiteSpace: "nowrap"}:
              {backgroundColor: "#45919d", minWidth: "5rem", color: "white", marginRight: "3px", borderRadius: "5px 5px 0 0", whiteSpace: "nowrap"}}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {option.label}
          </Button>
      }
      { option.type === 'icon' &&
          <Button
            id="demo-customized-button"
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
            style={{display: "flex", color: "gold", padding: "5px 1rem 2px 0.5rem"}}
          >
            <div className="flex-row"><FaBell size={25} />  {option.submenus.length}</div>
          </Button>
      }
      { option.type !== 'icon' &&
        <Menu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {option.submenus.map((menu) => (
            <MenuItem disableRipple 
                style={{borderBottom: "1px solid #dddddd", padding: '10px 40px 10px 30px', 
                fontSize: '15px'}} 
              onClick={(e)=> {
                onMenuClick(menu, option.label, menu.link)
                handleClose(e)
              }}>
              {menu.label}
            </MenuItem>))}
        </Menu>
      }
      { option.type === 'icon' &&
        <Menu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {option.submenus.map((menu) => (
            <MenuItem disableRipple 
                style={{borderBottom: "1px solid #dddddd",  
                fontSize: '15px'}} 
              onClick={(e)=> {
                onMenuClick(menu, option.label, menu.link)
                handleClose(e)
              }}>
              <div className="flex-row">
                <div style={{display: "flex", color: "Orange", padding: "5px 1rem 2px 0.5rem"}}>
                  <FaExclamationTriangle size={25} /> 
                </div>
                <div>
                  <div className="flex-column">
                    <div><h6>{menu.label}</h6></div>
                    <div style={{fontSize: "0.8rem"}}>{menu.description}</div>
                  </div>
                </div>
              </div>
            </MenuItem>))}
        </Menu>
      }
    </div>
  );
};
const Wrapper = styled.div`
  width: 100%;
  z-index: 10;
  padding: 0.5rem;
  padding-bottom: 0;
  color: white;
  background: #15616d;
  box-shadow: 0 2px 5px 0 grey;

  .main-menu {
    borderBottom: "1px solid #dddddd";
    padding: "10px 40px 10px 30px";
    fontSize: "15px";
  }
  .menu-slider {
    display: none;
    cursor: pointer;
  }
  .menu-list {
    overflow: hidden;
  }

  .page-title-default {
    display: none;
  }

  .page-title {
    display: flex;
  }

  @media screen and (max-width: 1100px) {
    .admin {
      padding-left: 0;
    }
    .menu-slider {
      display: flex;
    }
  }
  @media screen and (max-width: 750px) {
    .page-title {
      display: none;
    }
    .page-title-default {
      display: flex;
    }
  }
`;
